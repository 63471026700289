import styled from 'styled-components';
import getPriceDisplay from './getPriceDisplay';
import Text from '../Text';

const PriceText = styled(Text).attrs(
  ({ children, currencyCode, ...props }) => ({
    fontType: 'Body',
    color: 'INK_800',
    ...props,
    children: getPriceDisplay(children, currencyCode),
  }),
)``;

export default PriceText;
