import { useEffect, useRef, useContext, useCallback } from 'react';
import { createSelector } from 'reselect';
import prop from 'lodash/fp/prop';
import {
  cond,
  iteratee,
  stubTrue,
  conforms,
  flow,
  subtract,
  round,
} from 'lodash-es';
import gt from 'lodash/fp/gt';
import sumBy from 'lodash/fp/sumBy';
import divide from 'lodash/fp/divide';
import multiply from 'lodash/fp/multiply';
import ToastContext from 'contexts/Toast';
import PostMessageContext from 'contexts/PostMessage';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryGoogleAdsReport } from './useGoogleAdsReport';

const totalCreditsSelector = createSelector(
  prop(['creditOrders']),
  sumBy('credits'),
);

const totalCostSelector = flow(
  prop(['report', 'data', 'overall', 'cost']),
  divide(divide.placeholder, 10 ** 6),
);

const balanceSelector = createSelector(
  totalCreditsSelector,
  totalCostSelector,
  subtract,
);

const testBalance = cond([
  [
    iteratee(['currencyCode', 'TWD']),
    conforms({ balance: flow(round, gt(1000)) }),
  ],
  [stubTrue, conforms({ balance: flow(multiply(100), round, gt(27000)) })],
]);

const currencyCodeSelector = prop([
  'report',
  'data',
  'overall',
  'currencyCode',
]);

const useCreditAlert = () => {
  const oneOff = useRef(true);
  const { t } = useTranslation('main');
  const { data: googleAdsReport } = useQueryGoogleAdsReport();

  const { createUIToast } = useContext(ToastContext);
  const [
    { requestAdminHost: adminHost, requestMarketingPath: marketingPath },
    { requestAdminHost, requestMarketingPath, redirectToInnerPath },
  ] = useContext(PostMessageContext);

  const currencyCode = currencyCodeSelector(googleAdsReport);
  const balance = balanceSelector(googleAdsReport);

  const shouldAlert = testBalance({
    currencyCode,
    balance,
  });

  const handleClickRedirect = useCallback(
    (event) => {
      event.preventDefault();
      if (typeof redirectToInnerPath !== 'function') {
        return;
      }
      if (typeof marketingPath !== 'string' || !marketingPath.length) {
        return;
      }
      redirectToInnerPath(marketingPath);
    },
    [redirectToInnerPath, marketingPath],
  );

  useEffect(() => {
    requestAdminHost();
    requestMarketingPath();
  }, [requestAdminHost, requestMarketingPath]);

  useEffect(() => {
    if (oneOff.current && shouldAlert && adminHost && marketingPath) {
      oneOff.current = false;
      createUIToast({
        id: 'credit-alert',
        type: 'warning',
        title: 'main:Credit Alert',
        descriptionNodeWithProps: {
          node: Trans,
          props: {
            t,
            i18nKey: 'Add value',
            components: {
              /* eslint-disable jsx-a11y/anchor-has-content */
              anchor: (
                <a
                  href={`${adminHost}${marketingPath}`}
                  onClick={handleClickRedirect}
                />
              ),
              /* eslint-enable jsx-a11y/anchor-has-content */
            },
          },
        },
      });
    }
  }, [
    oneOff,
    createUIToast,
    shouldAlert,
    t,
    handleClickRedirect,
    adminHost,
    marketingPath,
  ]);

  return { value: balance, currencyCode };
};

export default useCreditAlert;
