import { useTranslation } from 'react-i18next';
import { Table } from '@shopline/dashboard-ui';
import { getPriceDisplay } from 'components/PriceText';
import { Section, SectionCaption, SectionSubtitle } from './components';
import useDataTable from './useDataTable';
import useCreditBalance from './useCreditBalance';

const DataTable = () => {
  const { t } = useTranslation('main');
  const { columns, dataSources } = useDataTable();
  const { value: balance, currencyCode } = useCreditBalance();
  return (
    <Section title={t('Google Ads Analytics')}>
      <SectionSubtitle>
        {t('balance', {
          displayBalance: getPriceDisplay(balance, currencyCode),
        })}
      </SectionSubtitle>
      <SectionCaption>{t('table description')}</SectionCaption>
      <Table
        headColumnAlign="center"
        rowVerticalAlign="middle"
        columns={columns}
        dataSource={dataSources}
      />
    </Section>
  );
};

export default DataTable;
