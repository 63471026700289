const main = {
  'App Description':
    '如果你有參與 SHOPLINE Google 廣告旗艦計畫，可於此頁面查看由 SHOPLINE 替你投放的 Google Ads 數據與成效。若廣告餘額不足，敬請至 <anchor>廣告計畫頁面</anchor> 購買廣告金做儲值。',
  marketing_plan_url: 'https://marketing.shoplineapp.com/pages/project-google',
  marketing_plan_url_MYR: 'https://marketing.shopline.my/eliteplan',
  marketing_plan_url_HKD: 'https://adseshop.shoplineapp.com/',
  'Google Ads Analytics': 'Google Ads 分析',
  'Credit Alert': '提醒您，您購買的廣告預算餘額即將使用完畢',
  'Add value': '如欲繼續投放廣告，請至<anchor>點我</anchor>前往加值 謝謝！',
  DATA_TABLE: {
    DATE: '日期',
    COST: '支出',
    IMPRESSIONS: '曝光數',
    CLICKS: '點擊數',
    CONVERSIONS: '轉換數（訂單數）',
  },
  'LAST 30 DAYS': '（最近30天）',
  balance: '廣告預算餘額: {{ displayBalance }}',
  'table description': '*分析數據會於每晚十二點更新一次',
  'get report failed': '權限不足故無法檢視此頁面，請聯繫您的廣告操作管理員。',
};

export default main;
