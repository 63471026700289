import { Trans, useTranslation } from 'react-i18next';
import prop from 'lodash/fp/prop';
import { useQueryInitialState } from 'hooks/useInitialState';
import { Page, PageDescription, PageTitle } from './components';
import useBreadcrumb from './useBreadcrumb';
import GoogleAdsReport from './GoogleAdsReport';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-index`;

const baseCurrencyCodeSelector = prop(['merchant', 'base_currency_code']);

const Main = () => {
  const { t } = useTranslation(['main', 'pageTitle']);

  const breadcrumbs = useBreadcrumb({
    e2ePrefix: `${E2E_PREFIX}-use-breadcrumb`,
  });

  const { data: initialState } = useQueryInitialState();
  const baseCurrencyCode = baseCurrencyCodeSelector(initialState);

  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <Page
      breadcrumbs={breadcrumbs}
      titleLeftSection={
        <PageTitle>{t('Google Ads Analytics', { ns: 'pageTitle' })}</PageTitle>
      }
    >
      <PageDescription>
        <Trans
          t={t}
          i18nKey="App Description"
          components={{
            anchor: (
              <a
                target="_blank"
                rel="noreferrer"
                href={t('marketing_plan_url', {
                  context: baseCurrencyCode,
                })}
              />
            ),
          }}
        />
      </PageDescription>
      <GoogleAdsReport />
    </Page>
  );
  /* eslint-enable jsx-a11y/anchor-has-content */
};

export default Main;
