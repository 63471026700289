import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useBreadcrumb = ({ e2ePrefix }) => {
  const { t } = useTranslation('pageTitle');

  const breadcrumbs = useMemo(
    () => [
      {
        id: t('Analytics'),
        label: t('Analytics'),
        e2eId: `${e2ePrefix}-analytics`,
      },
      {
        id: t('Google Ads Analytics'),
        label: t('Google Ads Analytics'),
        e2eId: `${e2ePrefix}-google-ads`,
      },
    ],
    [e2ePrefix, t],
  );

  return breadcrumbs;
};

export default useBreadcrumb;
