import { useContext, useCallback } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import prop from 'lodash/fp/prop';
import getOr from 'lodash/fp/getOr';
import { useQueryInitialState } from 'hooks/useInitialState';
import ToastContext from 'contexts/Toast';

const getGoogleAdsReport = async (merchantId) => {
  const { data } = await axios.get(
    `/api/merchants/${merchantId}/google-ads-report`,
  );
  return data;
};

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [_, merchantId] }) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId) => ['google-ads-report', merchantId];

const merchantIdSelector = prop(['merchant', 'id']);

const hasReportPermissionSelector = getOr(false, [
  'permissions',
  'analytics',
  'index',
]);

export const useQueryGoogleAdsReport = () => {
  const { createUIToast } = useContext(ToastContext);
  const { data: initialState } = useQueryInitialState();
  const merchantId = merchantIdSelector(initialState);
  const handleError = useCallback(() => {
    createUIToast({
      id: 'get-report-failed',
      type: 'alert',
      description: 'main:get report failed',
    });
  }, [createUIToast]);
  return useQuery(
    composeQueryKey(merchantId),
    passMerchantId(getGoogleAdsReport),
    {
      enabled: !!merchantId && hasReportPermissionSelector(initialState),
      onError: handleError,
    },
  );
};
