const main = {
  'App Description':
    '如果你有参与 SHOPLINE Google 广告旗舰计划，可于此页面查看由 SHOPLINE 替你投放的 Google Ads 数据与成效。若广告逾额不足，请至 <anchor>广告计划页面</anchor> 充值。',
  marketing_plan_url: 'https://marketing.shoplineapp.com/pages/project-google',
  marketing_plan_url_MYR: 'https://marketing.shopline.my/eliteplan',
  marketing_plan_url_HKD: 'https://adseshop.shoplineapp.com/',
  'Google Ads Analytics': 'Google Ads 分析',
  'Credit Alert': '提醒您, 您购买的广告预算余额即将使用完毕',
  'Add value': '如欲继续投放广告，请至<anchor>点我</anchor>前往加值 谢谢！',
  DATA_TABLE: {
    DATE: '日期',
    COST: '支出',
    IMPRESSIONS: '曝光数',
    CLICKS: '点击数',
    CONVERSIONS: '转换数（订单数）',
  },
  'LAST 30 DAYS': '（最近30天）',
  balance: '广告预算余额: {{ displayBalance }}',
  'table description': '*分析数据会于每晚十二点更新一次',
  'get report failed': '权限不足故无法检视此页面，请联系您的广告操作管理员。',
};

export default main;
