const main = {
  'App Description':
    "Những khách hàng tham gia gói SHOPLINE's Google Ads có thể kiểm tra kết quả và số liệu thống kê của Google Ads tại trang này. Nếu không còn đủ tiền trong tài khoản, vui lòng nạp thêm <anchor>tại đây</anchor>.",
  marketing_plan_url: 'https://marketing.shoplineapp.com/pages/project-google',
  marketing_plan_url_MYR: 'https://marketing.shopline.my/eliteplan',
  marketing_plan_url_HKD: 'https://adseshop.shoplineapp.com/',
  'Google Ads Analytics': 'Google Ads Analytics',
  'Credit Alert':
    'Để nhắc bạn, số dư ngân sách quảng cáo đã mua của bạn sắp được sử dụng hết.',
  'Add value':
    'Nếu bạn muốn tiếp tục quảng cáo, vui lòng <anchor>nhấp vào tôi</anchor> để thêm giá trị. Xin cảm ơn!',
  DATA_TABLE: {
    DATE: 'Ngày',
    COST: 'Số tiền chi',
    IMPRESSIONS:
      'Số lần hiển thị quảng cáo trên kết quả tìm kiếm (Ad Impressions)',
    CLICKS: 'Nhấp chuột',
    CONVERSIONS: 'Chuyển đổi',
  },
  'LAST 30 DAYS': '(30 ngày trước)',
  balance: 'Ngân sách còn lại: {{ displayBalance }}',
  'table description': '*Bảng này được cập nhật lúc 12h mỗi đêm',
  'get report failed':
    'Không đủ quyền để xem trang này, vui lòng liên hệ với quản trị viên hoạt động quảng cáo của bạn.',
};

export default main;
