const main = {
  'App Description':
    'Participants of SHOPLINE\'s Google Ads Plan can review your Google Ads\' performance and statistics on this page. If there are insufficient funds in your account, please recharge your balance "<anchor>here</anchor>".',
  marketing_plan_url: 'https://marketing.shoplineapp.com/pages/project-google',
  marketing_plan_url_MYR: 'https://marketing.shopline.my/eliteplan',
  marketing_plan_url_HKD: 'https://adseshop.shoplineapp.com/',
  'Google Ads Analytics': 'Google Ads Analytics',
  'Credit Alert':
    'To remind you, the balance of your purchased advertising budget is about to be used up.',
  'Add value':
    'If you want to continue advertising, please <anchor>click me</anchor> to add value. Thank you!',
  DATA_TABLE: {
    DATE: 'Date',
    COST: 'Amount Spent',
    IMPRESSIONS: 'Ad Impressions',
    CLICKS: 'Clicks',
    CONVERSIONS: 'Conversions',
  },
  'LAST 30 DAYS': '(Last 30 Days)',
  balance: 'Remaining Budget: {{ displayBalance }}',
  'table description': '*The table gets updated at 12 every midnight',
  'get report failed':
    'Insufficient permissions to view this page, please contact your ads operation administrator.',
};

export default main;
