const main = {
  'App Description':
    'ผู้เข้าร่วมแผน Google Ads ของ SHOPLINE สามารถตรวจสอบผลดำเนินการ Google Ads และสถิติของคุณได้ที่หน้านี้ หากเงินในบัญชีของคุณมีไม่เพียงพอ โปรดเติมเงิน "<anchor>ที่นี่</anchor>".',
  marketing_plan_url: 'https://marketing.shoplineapp.com/pages/project-google',
  marketing_plan_url_MYR: 'https://marketing.shopline.my/eliteplan',
  marketing_plan_url_HKD: 'https://adseshop.shoplineapp.com/',
  'Google Ads Analytics': 'Google Ads Analytics',
  'Credit Alert': 'เพื่อย้ำเตือนว่า งบโฆษณาที่คุณซื้อมากำลังจะหมดลง',
  'Add value':
    'หากคุณต้องการโฆษณาต่อ โปรด<anchor>คลิกฉัน</anchor>เพื่อเพิ่มมูลค่า ขอบคุณ!',
  DATA_TABLE: {
    DATE: 'วันที่',
    COST: 'จำนวนที่ใช้ไป',
    IMPRESSIONS: 'จำนวนครั้งที่แสดงโฆษณาออกไป (Ad Impressions)',
    CLICKS: 'คลิก',
    CONVERSIONS: 'Conversions',
  },
  'LAST 30 DAYS': '(30 วันที่ผ่านมา)',
  balance: 'งบประมาณคงเหลือ: {{ displayBalance }}',
  'table description': '*ตารางจะได้รับการอัปเดตทุกวันเวลา 12.00น.',
  'get report failed':
    'สิทธิ์ไม่เพียงพอในการดูหน้านี้ โปรดติดต่อผู้ดูแลระบบโฆษณาของคุณ',
};

export default main;
