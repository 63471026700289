import { Loading } from 'components';
import { useQueryGoogleAdsReport } from './useGoogleAdsReport';
import DataTable from './DataTable';

const GoogleAdsReport = () => {
  const { isLoading, isSuccess, data } = useQueryGoogleAdsReport();
  if (isLoading) {
    return <Loading />;
  }
  if (!isSuccess) {
    return null;
  }
  if (data === null) {
    return null;
  }
  return <DataTable />;
};

export default GoogleAdsReport;
