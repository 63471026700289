import {
  cond,
  flow,
  overEvery,
  negate,
  isNumber,
  isNaN,
  stubTrue,
} from 'lodash-es';
import replace from 'lodash/fp/replace';

const priceFormats = {
  TWD: new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'TWD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format,
  HKD: new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'HKD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format,
  VND: new Intl.NumberFormat('vi', {
    style: 'currency',
    currency: 'VND',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format,
  MYR: new Intl.NumberFormat('ms', {
    style: 'currency',
    currency: 'MYR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format,
  THB: new Intl.NumberFormat('th', {
    style: 'currency',
    currency: 'THB',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format,
  SGD: flow(
    new Intl.NumberFormat('en-SG', {
      style: 'currency',
      currency: 'SGD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format,
    replace('$', 'S$'),
  ),
  USD: new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format,
};

export const getNumberDisplay = (number, currencyCode = 'TWD') => {
  const priceFormatCurrency = priceFormats[currencyCode];
  if (priceFormatCurrency) {
    return priceFormatCurrency(number);
  }
  return priceFormats.USD(number);
};

export const getNaNDisplay = flow(getNumberDisplay, replace('NaN', '-'));

export default cond([
  [overEvery([isNumber, negate(isNaN)]), getNumberDisplay],
  [stubTrue, getNaNDisplay],
]);
