import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector, createStructuredSelector } from 'reselect';
import { flow } from 'lodash-es';
import prop from 'lodash/fp/prop';
import divide from 'lodash/fp/divide';
import { getPriceDisplay } from 'components/PriceText';
import numberFormat from 'utils/numberFormat';
import { useQueryGoogleAdsReport } from './useGoogleAdsReport';

const KEY_PREFIX = 'DATA_TABLE';
const KEYS = {
  DATE: `${KEY_PREFIX}.DATE`,
  COST: `${KEY_PREFIX}.COST`,
  IMPRESSIONS: `${KEY_PREFIX}.IMPRESSIONS`,
  CLICKS: `${KEY_PREFIX}.CLICKS`,
  CONVERSIONS: `${KEY_PREFIX}.CONVERSIONS`,
};
const COLUMNS = [
  { key: KEYS.DATE, width: 13 },
  { key: KEYS.COST, width: 12 },
  { key: KEYS.IMPRESSIONS, width: 11 },
  { key: KEYS.CLICKS, width: 11 },
  { key: KEYS.CONVERSIONS, width: 11 },
];

const costSelector = flow(prop(['cost']), divide(divide.placeholder, 10 ** 6));
const currencyCodeSelector = prop(['currencyCode']);
const displayCostSelector = createSelector(
  costSelector,
  currencyCodeSelector,
  getPriceDisplay,
);

const dataSourceSelector = createStructuredSelector({
  [KEYS.DATE]: prop(['displayDate']),
  [KEYS.COST]: displayCostSelector,
  [KEYS.IMPRESSIONS]: flow(prop(['impressions']), numberFormat),
  [KEYS.CLICKS]: flow(prop(['clicks']), numberFormat),
  [KEYS.CONVERSIONS]: flow(prop(['conversions']), numberFormat),
});

const useDataTable = () => {
  const { t } = useTranslation('main');
  const {
    data: {
      report: {
        data: { lastMonthDataset },
      },
    },
  } = useQueryGoogleAdsReport();
  return useMemo(() => {
    const lastMonth = lastMonthDataset.reduce((accumulator, item) => ({
      currencyCode: accumulator.currencyCode,
      cost: accumulator.cost + item.cost,
      impressions: accumulator.impressions + item.impressions,
      clicks: accumulator.clicks + item.clicks,
      conversions: accumulator.conversions + item.conversions,
    }));
    return {
      columns: COLUMNS.map((column) => ({
        ...column,
        align: 'center',
        title: t(column.key),
      })),
      dataSources: [
        dataSourceSelector({
          displayDate: t('LAST 30 DAYS'),
          ...lastMonth,
        }),
        ...lastMonthDataset.map(dataSourceSelector).reverse(),
      ],
    };
  }, [t, lastMonthDataset]);
};

export default useDataTable;
